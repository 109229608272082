import { refreshToken } from '../services/apiUsers';
import { DataSim } from './DataSim';

export const getMultipleRandom = (arr) => {
  const randomObject = [...arr].sort(() => 0.5 - Math.random());
  return randomObject;
};
export const totalDiscount = (price, priceOld) => {
  let calcNumber;
  let priceNumber = price.replace(/[^a-zA-Z0-9 ]/g, '');
  let priceOldNumber = priceOld.replace(/[^a-zA-Z0-9 ]/g, '');
  let disCount = priceOldNumber - priceNumber;
  calcNumber = Math.round((disCount / priceOldNumber) * 100) + '%';
  calcNumber = calcNumber === 'NaN%' ? '' : calcNumber;
  return calcNumber;
};
function roundUp(money) {
  const lastDigit = money % 100000;
  let roundedMoney = money;
  if (lastDigit > 50000) {
    roundedMoney += 100000 - lastDigit;
  } else if (lastDigit === 50000) {
    roundedMoney = money;
  } else {
    roundedMoney -= lastDigit;
  }
  return roundedMoney;
}
export const totalPriceDiscount = (sim, simDiscount) => {
  let dis = [];
  simDiscount.map((priceDF) => {
    if (parseInt(sim.price) >= parseInt(priceDF.price_start) && parseInt(sim.price) <= parseInt(priceDF.price_end)) {
      const price = numberFormat(roundUp(sim.price - sim.price * (priceDF.discount / 100)).toString());
      const pricerose = numberFormat(
        (sim.price - roundUp(sim.price - sim.price * (priceDF.discount / 100))).toString(),
      );
      dis = [price, pricerose, priceDF.discount];
    }
  });
  return dis;
};
export const totalPriceRose = (sim, simDiscount) => {
  let dis;
  simDiscount.map((priceDF) => {
    if (sim.price >= priceDF.price_start && sim.price <= priceDF.price_end) {
      dis = numberFormat((sim.price - (sim.price - sim.price * (priceDF.discount / 100))).toString());
    }
  });
  return dis;
};
export const numberFormat = (number) => {
  let calcNumber;
  let numberPrice = number?.toString()?.replace(/[^a-zA-Z0-9 ]/g, '');
  if (isNaN(numberPrice)) {
    calcNumber = number;
  } else {
    calcNumber = new Intl.NumberFormat('vi-VN', { minimumFractionDigits: 0 }).format(numberPrice);
  }
  return calcNumber;
};

export const numberFormatText = (number) => {
  let num = number?.toString().replace(/[^a-zA-Z0-9 ]/g, '');
  if (isNaN(num)) {
    num = number;
  } else {
    if (num < 1000) {
      return num;
    }
    let si = [
      { v: 1e3, s: 'K' },
      { v: 1e6, s: ' Triệu' },
      { v: 1e9, s: ' Tỷ' },
      { v: 1e12, s: ' T' },
      { v: 1e15, s: ' P' },
      { v: 1e18, s: ' E' },
    ];
    let index;
    for (index = si.length - 1; index > 0; index--) {
      if (num >= si[index].v) {
        break;
      }
    }
    num = (num / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[index].s;
  }
  return num;
};
export const numberFormatTextShorten = (number) => {
  let num = number?.toString()?.replace(/[^a-zA-Z0-9 ]/g, '');
  if (isNaN(num)) {
    num = number;
  } else {
    if (num < 1000) {
      return num;
    }
    let si = [
      { v: 1e3, s: 'K' },
      { v: 1e6, s: 'Tr' },
      { v: 1e9, s: 'Tỷ' },
      { v: 1e12, s: 'T' },
      { v: 1e15, s: 'P' },
      { v: 1e18, s: 'E' },
    ];
    let index;
    for (index = si.length - 1; index > 0; index--) {
      if (num >= si[index].v) {
        break;
      }
    }
    num = (num / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[index].s;
  }
  return num;
};
export const numberFormatTextCanvas = (number) => {
  let num = number.replace(/[^a-zA-Z0-9 ]/g, '');
  if (num < 1000) {
    return num;
  }
  let si = [
    { v: 1e3, s: 'K' },
    { v: 1e6, s: ' Tr' },
    { v: 1e9, s: ' Tỷ' },
    { v: 1e12, s: ' T' },
    { v: 1e15, s: ' P' },
    { v: 1e18, s: ' E' },
  ];
  let index;
  for (index = si.length - 1; index > 0; index--) {
    if (num >= si[index].v) {
      break;
    }
  }
  return (num / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[index].s;
};

export const numberFormatDeleteDots = (number) => {
  let numberPrice = number?.toString().replace(/[^a-zA-Z0-9 ]/g, '');
  // console.log(parseInt(numberPrice));
  // console.log(number.toString().replace(/\./g, ''));
  return parseInt(numberPrice);
};
export const mapRecords = (records, headerData) => {
  return records
    .filter((record) => record.length > 0)
    .map((record) =>
      record.reduce((obj, item, index) => {
        obj[headerData[index]] = item;
        return obj;
      }, {}),
    )
    .slice(1);
};

// tính ý nghĩa đại cat
export const favourite = (number) => {
  const last4Str = String(number).slice(-4);
  const last4Num = parseInt(last4Str);
  const tailnumbers = last4Num / 80;
  const surplus = getDecimalPart(tailnumbers);
  const totalHungCat = '0.' + surplus;
  const total = Math.round(parseFloat(totalHungCat) * 80);
  if (DataSim[total]['mucdo'] === 'Đại Cát' || DataSim[total]['mucdo'] === 'Cát') {
    return DataSim[total];
  } else {
    return '';
  }
};

const getDecimalPart = (num) => {
  // console.log(Number.isInteger(num), num);
  if (Number.isInteger(num)) {
    return 0;
  }

  const decimalStr = num.toString().split('.')[1];
  return parseInt(decimalStr);
};
export const getLocalStorage = (name) => {
  const local = localStorage.getItem(name);
  if (local != null) {
    return JSON.parse(decodeURIComponent(atob(local)));
  }
  return null;
};
export const setLocalStorage = (name, item) => {
  const encodeData = btoa(encodeURIComponent(JSON.stringify(item)));
  localStorage.setItem(name, encodeData);
};
export const removeLocalStorage = (name) => {
  return localStorage.removeItem(name);
};

export const checkTokenDashboad = (id = '1') => {
  const nowDate = Math.floor(Date.now() / 1000);
  const getToken = getLocalStorage('token');

  const hostname = window.location.hostname;
  let isStatus = false;
  if (getToken) {
    if (getToken.exp >= nowDate && getToken.link_domain === hostname && getToken.permission === id) {
      if (getToken.exp <= nowDate + 60 * 60 * 24 * 2 && getToken.exp >= nowDate) {
        refresh(getToken.username, hostname);
      }
      isStatus = true;
    } else {
      isStatus = false;
    }
  } else {
    isStatus = false;
  }
  return isStatus;
};

const refresh = async (username, hostname) => {
  const refresh = await refreshToken({ user: username, domain: hostname });
  setLocalStorage('token', { ...refresh['data'] });
  localStorage.setItem('token_sv', refresh['token']);
};

export const removeSpecialCharacters = (str) => {
  // Sử dụng biểu thức chính quy để tìm và thay thế các ký tự đặc biệt
  const regex = /[^0-9]/g;
  const result = str.replace(regex, '');

  return result;
};

// chuyển text -> link
export const convertToSlug = (str) => {
  str = str.toLowerCase();
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/đ/g, 'd');
  // Some system encode vietnamese combining accent as individual utf-8 characters
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // Huyền sắc hỏi ngã nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ''); // Â, Ê, Ă, Ơ, Ư
  str = str.replace(/([^\w]+|\s+)/g, '-');
  str = str.replace(/\-\-+/g, '-');
  return str;
};

// lấy dữ liệu cuối cùng
export const getLastValue = (str) => {
  if (str) {
    const parts = str?.split('-');
    const lastPart = parts[parts.length - 1];
    return parseInt(lastPart);
  }
};
export const handleConvertMonths = (number) => {
  const num = parseInt(number);

  if (isNaN(num)) {
    return 'Invalid number';
  }

  if (num < 0) {
    return 'Number must be positive';
  }

  if (num < 30) {
    return `${num} ngày`;
  } else if (num < 360) {
    const months = Math.floor(num / 30);
    return `${months} tháng`;
  } else {
    const years = Math.floor(num / 360);
    return `${years} năm`;
  }
};
