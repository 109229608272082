import PropTypes from 'prop-types';
import SimCardIcon from '@mui/icons-material/SimCard';
import DoneIcon from '@mui/icons-material/Done';
import LoyaltyTwoToneIcon from '@mui/icons-material/LoyaltyTwoTone';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';

import classNames from 'classnames/bind';
import styles from './FrameInfoSim.module.scss';
import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Stack, Typography } from '@mui/material';
import {
  convertToSlug,
  getLocalStorage,
  numberFormat,
  numberFormatText,
  setLocalStorage,
  totalDiscount,
} from '../../../utils/helper';
import { Link, useNavigate } from 'react-router-dom';
import SimpleSnackbar from './NotifiAddCart';
import { useState } from 'react';
const cx = classNames.bind(styles);

function FrameImageShop({
  bgrFrame = 'df',
  title = '',
  price = '',
  price_old = '',
  product_code = '',
  fontSize = 'small',
  image = '',
  id = 1,
  onClick,
}) {
  let CompIcon = DoneIcon;
  const props = { onClick };
  const navigate = useNavigate();
  const [openCart, setOpenCart] = useState(false);

  const redirectDetailShop = (title, id) => {
    navigate(`/shop/${convertToSlug(title)}.${id}`, { replace: true });
  };

  const onAddShop = (title, price, cate, soluong = 1, code) => {
    let obj = { title: title, price: price, cate: cate, soluong: soluong, code: code };
    let arrShop = getLocalStorage('addShop') || [];
    setLocalStorage('addShop', [...arrShop, obj]);
    setOpenCart(true);
    setTimeout(() => {
      setOpenCart(false);
    }, 3000);
  };
  return (
    <Card>
      <CardActionArea onClick={() => redirectDetailShop(title, id)}>
        <CardMedia component="img" height="181" image={image} alt="green iguana" />
        <CardContent sx={{ p: 1 }}>
          <h5 className={cx('title-css')}>{title}</h5>
          <div className={cx('h-50')}>
            <b className={cx('price-item')}>
              {price && numberFormat(price)} <sup>₫</sup>
            </b>
            {price_old ? (
              <Stack direction="row" spacing={0.5}>
                <span className={cx('font-size-tool-sale', 'space-icon-sale')}>
                  <LoyaltyTwoToneIcon className={cx('font-size-tool-sale-icon')} color="error" />
                  <b>{price_old && totalDiscount(price, price_old)}</b>
                </span>
                <b className={cx('price-old-item')}>
                  {price_old && numberFormat(price_old)} <sup>₫</sup>
                </b>
              </Stack>
            ) : null}
          </div>
        </CardContent>
      </CardActionArea>
      <CardActions className={cx('bt')} sx={{ justifyContent: 'flex-end' }}>
        <Button size="small" color="success" variant="contained">
          <AddShoppingCartIcon onClick={() => onAddShop(title, price, 'sp', 1, product_code)} />
        </Button>
        <Button
          size="small"
          color="error"
          variant="contained"
          component={Link}
          to={`/shop/${convertToSlug(title)}.${id}`}
        >
          Mua ngay
        </Button>
        {openCart && <SimpleSnackbar Notifi="Đã thêm vào giỏ hàng" />}
      </CardActions>
    </Card>
  );
}

FrameImageShop.propTypes = {
  bgrFrame: PropTypes.string,
  title: PropTypes.string,
  fontSize: PropTypes.string,
  image: PropTypes.string,
  onClick: PropTypes.func,
};

export default FrameImageShop;
