import { useContext, useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';

import { Box, Checkbox, CircularProgress, Container, Grid, Hidden, Stack, TextField } from '@mui/material';

import images from '../../../assets/images';
import { listUserID } from '../../../services/apiUsers';
import { ThemeContext } from '../../../component/ThemeContext/ThemeContext';

import styles from '../../DigiShop/digishop.module.scss';
import { favourite, getLocalStorage, numberFormatDeleteDots, numberFormatText } from '../../../utils/helper';
import { ButtonUI } from '../../../component/CustomerMUI';
import OrderSimDetail from './orderSim/ordersim';
import { getFlashSale } from '../../../services/apiFlashSale';
import { LogicNetWork } from '../LogicSearch/LogicNetWork';
const cx = classNames.bind(styles);

function FlashsaleShop() {
  const [timer, setTimer] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const hostname = window.location.hostname;
  const loader = useRef(null);
  const [page, setPage] = useState(1);
  const [resultSim, setResultSim] = useState([]);
  const [totalRows, settotalRows] = useState(0);
  const [loading, setLoading] = useState(true);
  const [authDomain, setAuthDomain] = useState({});
  const contextApiUser = useContext(ThemeContext);
  const [open, setOpen] = useState(false);
  const [detailSim, setDetailSim] = useState({});
  const [checkCoppy, setCheckCoppy] = useState({});
  const [iconCopy, setIconCopy] = useState('🍂');
  const [textCopiedSimAndPrice, setTextCopiedSimAndPrice] = useState('');
  const [textCopied, setTextCopied] = useState('');
  const [textCopiedChot, setTextCopiedChot] = useState('');
  const [resultFlash, setResultFlash] = useState({});
  const [resultFlashData, setResultFlashData] = useState([]);
  const navigate = useNavigate();

  const fetchApiUserID = async () => {
    const resultAPISIM = await listUserID(hostname);
    if (resultAPISIM.length > 0) {
      setAuthDomain(resultAPISIM[0]);
      fetchApiFlashSale(resultAPISIM[0]['id']);
      contextApiUser.toggleDataUser(resultAPISIM[0]);
      document.body.style.backgroundColor = resultAPISIM[0]['background']?.split(',')[0] + '0f';
    }
  };

  const fetchApiFlashSale = async (id_user) => {
    const resultAPISIM = await getFlashSale(id_user);
    if (resultAPISIM && resultAPISIM.length > 0) {
      const currentTime = new Date().toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' });
      const sortedData = [...resultAPISIM].sort((a, b) => a.time_start.localeCompare(b.time_start));

      const ongoingRecords = sortedData.filter(
        (record) => record.time_start <= currentTime && record.time_end >= currentTime,
      );
      const upcomingRecords = sortedData.filter((record) => record.time_start > currentTime);
      setResultFlash({ dienra: ongoingRecords, sapdienra: upcomingRecords });
      setResultFlashData(ongoingRecords[0]?.text_sim?.split('\n'));
    }
  };

  useEffect(() => {
    document.title = 'Sim số đẹp sale cực rẻ';
    fetchApiUserID();
  }, []);

  useEffect(() => {
    if (resultFlash?.dienra?.length > 0) {
      const interval = setInterval(() => {
        const currentTime = new Date().toLocaleTimeString('en-US', { hour12: false });
        const timeStartInSeconds = new Date(`1970-01-01T${resultFlash?.dienra[0].time_start}`).getTime() / 1000;
        const timeEndInSeconds = new Date(`1970-01-01T${resultFlash?.dienra[0].time_end}`).getTime() / 1000;
        const currentTimeInSeconds = new Date(`1970-01-01T${currentTime}`).getTime() / 1000;
        const timeRemainingInSeconds = timeEndInSeconds - currentTimeInSeconds;

        if (timeRemainingInSeconds <= 0) {
          clearInterval(interval);
          setTimer({
            hours: 0,
            minutes: 0,
            seconds: 0,
          });
          return;
        }

        const formattedTimeRemaining = new Date(timeRemainingInSeconds * 1000)
          ?.toISOString()
          ?.substr(11, 8)
          ?.split(':');

        setTimer({
          hours: formattedTimeRemaining[0],
          minutes: formattedTimeRemaining[1],
          seconds: formattedTimeRemaining[2],
        });
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [resultFlash]);

  //   useEffect(() => {
  //     if (page !== 1 && Math.ceil(totalRows / 30) >= page) {
  //       setLoading(true);
  //       fetchApiSimDigi();
  //     }
  //   }, [page]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '20px',
      threshold: 1.0,
    };

    const observer = new IntersectionObserver(handleObserver, options);
    if (loader.current) observer.observe(loader.current);

    return () => observer.disconnect();
  }, [totalRows]);

  const handleObserver = (entities) => {
    const target = entities[0];
    if (target.isIntersecting && Math.ceil(totalRows / 30) >= page) {
      setPage((page) => page + 1);
    }
  };

  const handleModalOpen = (sim) => {
    const simandprice = sim.split('=');
    const favoriteSim = favourite(simandprice[0].toString()) && favourite(simandprice[0].toString())['ynghia'];
    const simDetailDailog = {
      sim: simandprice[0].toString(),
      price: simandprice[1].toString(),
      subCard: '',
      netWork: LogicNetWork(simandprice[0].replace(/\./g, '')),
      means: favoriteSim,
      hotline: getLocalStorage('khoso')['HOTTLINE_SIM'],
      GMAIL_NOTIFI: getLocalStorage('khoso')['GMAIL_NOTIFI'],
      background: getLocalStorage('khoso')['background'].split(',')[0],
    };
    setOpen(true);
    setDetailSim(simDetailDailog);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCoppyAll = (e) => {
    let arrsim = {};
    if (e.target.checked) {
      resultSim?.map((sim, index) => {
        arrsim[index] = sim;
      });
      handleTextCopiedSimAndPrice(arrsim);
      handleTextCopied(arrsim);
      setCheckCoppy(arrsim);
    } else {
      setCheckCoppy({});
    }
  };

  const handleCoppy = (index, sim) => {
    let state = { ...checkCoppy, [index]: sim };
    setCheckCoppy(state);
    handleTextCopiedSimAndPrice(state);
    handleTextCopied(state);
  };
  const handleDeleteCoppy = (index) => {
    let state = { ...checkCoppy };
    delete state[index];
    setCheckCoppy(state);
    handleTextCopiedSimAndPrice(state);
    handleTextCopied(state);
  };

  const handleTextCopiedSimAndPrice = (state, iconcopy = iconCopy) => {
    let listSim = Object.values(state);
    let arrSim = listSim.map((sim) => {
      let SimsCopied = `${iconcopy?.trim()}${sim.sim_show}`;

      return SimsCopied;
    });
    if (arrSim.length > 0) {
      const re = /,/gi;
      const sims = arrSim.toString().replace(re, '\n');
      setTextCopiedSimAndPrice(sims);
    }
  };
  const handleTextCopied = (state) => {
    let listSim = Object.values(state);
    var simSo = [];
    var simPrice = 0;
    let arrSim = listSim.map((sim) => {
      const arrsimdep = sim.split('=');
      let SimsCopied = `- Sim: ${arrsimdep[0]}\n💰 Giá sale: ${numberFormatText(
        arrsimdep[1] || 0,
      )} \n👉 Mạng: ${LogicNetWork(arrsimdep[0])} \n- Thuê bao: Trả Trước\n- Tình trạng: sim còn ${
        listSim.length > 1 ? `\n-----------` : ''
      } `;
      simSo.push(
        `- Sim mua: ${arrsimdep[0]}\n👉 Mạng: ${LogicNetWork(arrsimdep[0])} ${
          listSim.length > 1 ? `\n-----------` : ''
        }`,
      );
      simPrice = numberFormatDeleteDots(simPrice) + numberFormatDeleteDots(arrsimdep[1] || 0);

      return SimsCopied;
    });
    if (arrSim.length > 0) {
      const re = /,/gi;
      const sims = arrSim.toString().replace(re, '\n');
      const simso = simSo.toString().replace(re, '\n');
      let textSimCopied = `KIỂM SIM\n${sims}`;
      let textSimCopiedChot = `✈️𝑻𝒉𝒐̂𝒏𝒈 𝒕𝒊𝒏 𝑪𝒉𝒐̂́𝒕 𝑺𝒊𝒎!!!\n- Tên KH:\n${simso}\n- Liên hệ KH:\n- Địa chỉ Khách: \n ☛ Tổng thu KH : ${numberFormatText(
        simPrice.toString(),
      )}`;
      setTextCopied(textSimCopied);
      setTextCopiedChot(textSimCopiedChot);
    }
  };

  const handleCopySim = (index) => {
    if (index === 1) {
      navigator.clipboard.writeText(textCopiedSimAndPrice);
    } else if (index === 2) {
      navigator.clipboard.writeText(textCopied);
    } else if (index === 3) {
      navigator.clipboard.writeText(textCopiedChot);
    }
    setCheckCoppy({});
    setTextCopiedSimAndPrice('');
    setTextCopied('');
    setTextCopiedChot('');
  };

  const handleCoppyIcon = (e) => {
    handleTextCopiedSimAndPrice(checkCoppy, e.target.value);
  };

  return (
    <>
      {detailSim && <OrderSimDetail openDiaLog={open} onClose={handleClose} detailSim={detailSim} />}
      {resultFlashData?.length > 0 && (
        <Container sx={{ pb: '70px', pl: '10px', pr: '10px' }}>
          <Grid container>
            <Grid item md={12} xs={12} sx={{ mt: 1 }}>
              <img src={images.bannerflashsale} alt={'khuyến mãi'} width={'100%'} className={cx('bdradius-10')} />
            </Grid>
            <Grid item md={12} xs={12} sx={{ mt: 1 }}>
              <Hidden only={['sm', 'xs']}>
                <Stack direction={'row'} spacing={1} justifyContent={'center'} alignItems={'center'}>
                  <span>---</span>
                  <img src={images.flashsale} alt={'vinaphone flash sale'} width={'115px'} />
                  <img src={images.lock} alt={'vinaphone flash sale'} width={'18px'} height={'100%'} />
                  <label>KẾT THÚC TRONG</label>
                  <p>
                    <b className={cx('cs-countdow')}>
                      {timer.hours.toString().length === 1 ? '0' + timer.hours : timer.hours}
                    </b>{' '}
                    :{' '}
                    <b className={cx('cs-countdow')}>
                      {timer.minutes.toString().length === 1 ? '0' + timer.minutes : timer.minutes}
                    </b>{' '}
                    :{' '}
                    <b className={cx('cs-countdow')}>
                      {timer.seconds.toString().length === 1 ? '0' + timer.seconds : timer.seconds}
                    </b>
                  </p>
                  <span>---</span>
                </Stack>
              </Hidden>
              <Hidden only={['md', 'lg', 'xl']}>
                <Stack direction={'row'} spacing={1} justifyContent={'center'} alignItems={'center'} sx={{ mb: 1 }}>
                  <img src={images.flashsale} alt={'vinaphone flash sale'} width={'115px'} />
                </Stack>
                <Stack direction={'row'} spacing={1} justifyContent={'center'} alignItems={'center'}>
                  <img src={images.lock} alt={'vinaphone flash sale'} width={'18px'} height={'100%'} />
                  <label>KẾT THÚC TRONG</label>
                  <p>
                    <b className={cx('cs-countdow')}>
                      {timer.hours.toString().length === 1 ? '0' + timer.hours : timer.hours}
                    </b>{' '}
                    :{' '}
                    <b className={cx('cs-countdow')}>
                      {timer.minutes.toString().length === 1 ? '0' + timer.minutes : timer.minutes}
                    </b>{' '}
                    :{' '}
                    <b className={cx('cs-countdow')}>
                      {timer.seconds.toString().length === 1 ? '0' + timer.seconds : timer.seconds}
                    </b>
                  </p>
                </Stack>
              </Hidden>
            </Grid>
            <Grid item md={12} xs={12} sx={{ mt: 1 }}>
              <div className={cx('block-list-filter', 'dis-center')}>
                <div className={cx('box-list-subcate', 'p-b-5')}>
                  <div className={cx('list-subcate')}>
                    <Stack direction="row" spacing={1}>
                      {resultFlash.dienra?.length > 0 && (
                        <Grid item className={cx('active-time')}>
                          <b>{resultFlash.dienra[0].time_start} (Đang diễn ra)</b>
                        </Grid>
                      )}
                      {resultFlash.sapdienra?.length > 0 &&
                        resultFlash.sapdienra.map((timesdr, index) => (
                          <Grid item className={cx('no-active-time')} key={index}>
                            <b>{timesdr.time_start} (Sắp diễn ra)</b>
                          </Grid>
                        ))}
                    </Stack>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid item md={12} xs={12} sx={{ mt: 1, pl: 1 }}>
            <Stack direction={'row'} alignItems={'center'} sx={{ m: 0, p: 0 }}>
              <Checkbox
                size="small"
                onClick={handleCoppyAll}
                // checked={(checkCoppy ? Object.values(checkCoppy).length : 0) > 0 ? true : false}
              />
              Tổng sim đang thanh lý: <b> {` ${resultFlashData.length} sim`}</b>
            </Stack>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 0.5 }}>
            {/* hiển thị sim */}
            {resultFlashData.map(
              (item, index) =>
                index % 10 === 0 && (
                  <Grid item md={4} xs={12} key={index}>
                    <div className={cx('phoi-all')}>
                      <div className={cx('phoi-all-2')}>
                        <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
                          <b className={cx('css-tieude-sale')}>Thay Sim Đổi Vận - Thu Hút Tài Lộc</b>
                        </Stack>
                      </div>
                      <div className={cx('phoi-all-sale')}>
                        <b className={cx('fl-left')}>
                          <Checkbox
                            size="small"
                            checked={
                              (checkCoppy[index] ? Object.values(checkCoppy[index]).length : 0) > 0 ? true : false
                            }
                            onClick={() =>
                              (checkCoppy[index] ? Object.values(checkCoppy[index]).length : 0) > 0
                                ? handleDeleteCoppy(index)
                                : handleCoppy(index, item)
                            }
                          />
                        </b>
                        <span onClick={() => handleModalOpen(item)}>{item}</span>
                      </div>
                      {resultFlashData[index + 1] && (
                        <div className={cx('phoi-all-sale')}>
                          <b className={cx('fl-left')}>
                            <Checkbox
                              size="small"
                              checked={
                                (checkCoppy[index + 1] ? Object.values(checkCoppy[index + 1]).length : 0) > 0
                                  ? true
                                  : false
                              }
                              onClick={() =>
                                (checkCoppy[index + 1] ? Object.values(checkCoppy[index + 1]).length : 0) > 0
                                  ? handleDeleteCoppy(index + 1)
                                  : handleCoppy(index + 1, resultFlashData[index + 1])
                              }
                            />
                          </b>
                          <span onClick={() => handleModalOpen(resultFlashData[index + 1])}>
                            {resultFlashData[index + 1]}
                          </span>
                        </div>
                      )}
                      {resultFlashData[index + 2] && (
                        <div className={cx('phoi-all-sale')}>
                          <b className={cx('fl-left')}>
                            <Checkbox
                              size="small"
                              checked={
                                (checkCoppy[index + 2] ? Object.values(checkCoppy[index + 2]).length : 0) > 0
                                  ? true
                                  : false
                              }
                              onClick={() =>
                                (checkCoppy[index + 2] ? Object.values(checkCoppy[index + 2]).length : 0) > 0
                                  ? handleDeleteCoppy(index + 2)
                                  : handleCoppy(index + 2, resultFlashData[index + 2])
                              }
                            />
                          </b>
                          <span onClick={() => handleModalOpen(resultFlashData[index + 2])}>
                            {resultFlashData[index + 2]}
                          </span>
                        </div>
                      )}
                      {resultFlashData[index + 3] && (
                        <div className={cx('phoi-all-sale')}>
                          <b className={cx('fl-left')}>
                            <Checkbox
                              size="small"
                              checked={
                                (checkCoppy[index + 3] ? Object.values(checkCoppy[index + 3]).length : 0) > 0
                                  ? true
                                  : false
                              }
                              onClick={() =>
                                (checkCoppy[index + 3] ? Object.values(checkCoppy[index + 3]).length : 0) > 0
                                  ? handleDeleteCoppy(index + 3)
                                  : handleCoppy(index + 3, resultFlashData[index + 3])
                              }
                            />
                          </b>
                          <span onClick={() => handleModalOpen(resultFlashData[index + 3])}>
                            {resultFlashData[index + 3]}
                          </span>
                        </div>
                      )}
                      {resultFlashData[index + 4] && (
                        <div className={cx('phoi-all-sale')}>
                          <b className={cx('fl-left')}>
                            <Checkbox
                              size="small"
                              checked={
                                (checkCoppy[index + 4] ? Object.values(checkCoppy[index + 4]).length : 0) > 0
                                  ? true
                                  : false
                              }
                              onClick={() =>
                                (checkCoppy[index + 4] ? Object.values(checkCoppy[index + 4]).length : 0) > 0
                                  ? handleDeleteCoppy(index + 4)
                                  : handleCoppy(index + 4, resultFlashData[index + 4])
                              }
                            />
                          </b>
                          <span onClick={() => handleModalOpen(resultFlashData[index + 4])}>
                            {resultFlashData[index + 4]}
                          </span>
                        </div>
                      )}
                      {resultFlashData[index + 5] && (
                        <div className={cx('phoi-all-sale')}>
                          <b className={cx('fl-left')}>
                            <Checkbox
                              size="small"
                              checked={
                                (checkCoppy[index + 5] ? Object.values(checkCoppy[index + 5]).length : 0) > 0
                                  ? true
                                  : false
                              }
                              onClick={() =>
                                (checkCoppy[index + 5] ? Object.values(checkCoppy[index + 5]).length : 0) > 0
                                  ? handleDeleteCoppy(index + 5)
                                  : handleCoppy(index + 5, resultFlashData[index + 5])
                              }
                            />
                          </b>
                          <span onClick={() => handleModalOpen(resultFlashData[index + 5])}>
                            {resultFlashData[index + 5]}
                          </span>
                        </div>
                      )}
                      {resultFlashData[index + 6] && (
                        <div className={cx('phoi-all-sale')}>
                          <b className={cx('fl-left')}>
                            <Checkbox
                              size="small"
                              checked={
                                (checkCoppy[index + 6] ? Object.values(checkCoppy[index + 6]).length : 0) > 0
                                  ? true
                                  : false
                              }
                              onClick={() =>
                                (checkCoppy[index + 6] ? Object.values(checkCoppy[index + 6]).length : 0) > 0
                                  ? handleDeleteCoppy(index + 6)
                                  : handleCoppy(index + 6, resultFlashData[index + 6])
                              }
                            />
                          </b>
                          <span onClick={() => handleModalOpen(resultFlashData[index + 6])}>
                            {resultFlashData[index + 6]}
                          </span>
                        </div>
                      )}
                      {resultFlashData[index + 7] && (
                        <div className={cx('phoi-all-sale')}>
                          <b className={cx('fl-left')}>
                            <Checkbox
                              size="small"
                              checked={
                                (checkCoppy[index + 7] ? Object.values(checkCoppy[index + 7]).length : 0) > 0
                                  ? true
                                  : false
                              }
                              onClick={() =>
                                (checkCoppy[index + 7] ? Object.values(checkCoppy[index + 7]).length : 0) > 0
                                  ? handleDeleteCoppy(index + 7)
                                  : handleCoppy(index + 7, resultFlashData[index + 7])
                              }
                            />
                          </b>
                          <span onClick={() => handleModalOpen(resultFlashData[index + 7])}>
                            {resultFlashData[index + 7]}
                          </span>
                        </div>
                      )}
                      {resultFlashData[index + 8] && (
                        <div className={cx('phoi-all-sale')}>
                          <b className={cx('fl-left')}>
                            <Checkbox
                              size="small"
                              checked={
                                (checkCoppy[index + 8] ? Object.values(checkCoppy[index + 8]).length : 0) > 0
                                  ? true
                                  : false
                              }
                              onClick={() =>
                                (checkCoppy[index + 8] ? Object.values(checkCoppy[index + 8]).length : 0) > 0
                                  ? handleDeleteCoppy(index + 8)
                                  : handleCoppy(index + 8, resultFlashData[index + 8])
                              }
                            />
                          </b>
                          <span onClick={() => handleModalOpen(resultFlashData[index + 8])}>
                            {resultFlashData[index + 8]}
                          </span>
                        </div>
                      )}
                      {resultFlashData[index + 9] && (
                        <div className={cx('phoi-all-sale')}>
                          <b className={cx('fl-left')}>
                            <Checkbox
                              size="small"
                              checked={
                                (checkCoppy[index + 9] ? Object.values(checkCoppy[index + 9]).length : 0) > 0
                                  ? true
                                  : false
                              }
                              onClick={() =>
                                (checkCoppy[index + 9] ? Object.values(checkCoppy[index + 9]).length : 0) > 0
                                  ? handleDeleteCoppy(index + 9)
                                  : handleCoppy(index + 9, resultFlashData[index + 9])
                              }
                            />
                          </b>
                          <span onClick={() => handleModalOpen(resultFlashData[index + 9])}>
                            {resultFlashData[index + 9]}
                          </span>
                        </div>
                      )}
                      <div className={cx('phoi-all-2')}>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                          <img src={images.phoisimdep} alt={'khoso'} width={'100px'} className={cx('bdradius-10')} />
                          <b className={cx('css-logo-sale')}>{hostname}</b>
                        </Stack>
                      </div>
                      <div className={cx('phoi-all-2')}>
                        <Stack direction={'column'} sx={{ mt: '-25px', lineHeight: '1.7' }} alignItems={'flex-end'}>
                          <span className={cx('cs-color-white')}>{resultFlash?.dienra[0]?.notes_sim}</span>
                          <span className={cx('cs-color-white')}> HOTLINE {authDomain?.HOTTLINE_SIM}</span>
                          <Stack
                            direction={'row'}
                            justifyContent={'flex-end'}
                            alignItems={'center'}
                            sx={{ color: '#fff' }}
                          >
                            <span className={cx('ml-10', 'mt-2')}>
                              <b className={cx('cs-countdow')}>{timer.hours}</b> :{' '}
                              <b className={cx('cs-countdow')}>{timer.minutes}</b> :{' '}
                              <b className={cx('cs-countdow')}>{timer.seconds}</b>
                            </span>
                          </Stack>
                        </Stack>
                      </div>
                    </div>
                  </Grid>
                ),
            )}
          </Grid>

          {/* <div ref={loader}>
            {loading && (
              <div className={cx('css-load-sim')}>
                <CircularProgress color="inherit" size={20} />
                <b className={cx('pl-1')}>Tải dữ liệu...</b>
              </div>
            )}
          </div> */}
          <Box
            style={{
              position: 'fixed',
              bottom: 360,
              right: 10,
              display: Object.values(checkCoppy).length > 0 ? 'block' : 'none',
              zIndex: 10,
            }}
          >
            <TextField
              label="Thêm icon:"
              size="small"
              sx={{ width: '100px', background: '#fff' }}
              variant="outlined"
              value={iconCopy}
              color="error"
              onChange={(e) => {
                setIconCopy(e.target.value);
                handleCoppyIcon(e);
              }}
              focused
            />
          </Box>
          <ButtonUI
            borderBtn="#bef5ff"
            color="info"
            style={{
              position: 'fixed',
              bottom: 320,
              right: 10,
              display: Object.values(checkCoppy).length > 0 ? 'block' : 'none',
              zIndex: 10,
            }}
            onClick={() => handleCopySim(1)}
          >
            Copy {Object.values(checkCoppy).length} sim
          </ButtonUI>
          <ButtonUI
            borderBtn="#bef5ff"
            color="info"
            style={{
              position: 'fixed',
              bottom: 280,
              right: 10,
              display: Object.values(checkCoppy).length > 0 ? 'block' : 'none',
              zIndex: 10,
            }}
            onClick={() => handleCopySim(2)}
          >
            Copy {Object.values(checkCoppy).length} sim chi tiết
          </ButtonUI>
          <ButtonUI
            borderBtn="#d1fae5"
            color="success"
            style={{
              position: 'fixed',
              bottom: 240,
              right: 10,
              display: Object.values(checkCoppy).length > 0 ? 'block' : 'none',
              zIndex: 10,
            }}
            onClick={() => handleCopySim(3)}
          >
            Mẫu đặt sim
          </ButtonUI>
        </Container>
      )}
    </>
  );
}

export default FlashsaleShop;
