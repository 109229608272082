import { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

import classNames from 'classnames/bind';
import styles from './flashsale.module.scss';
import {
  addFlashSaleID,
  deleFlashSale,
  getFlashSale,
  getFlashSaleID,
  updateFlashSaleID,
} from '../../../services/apiFlashSale';
import { checkTokenDashboad, getLocalStorage } from '../../../utils/helper';
import { useNavigate } from 'react-router-dom';
const cx = classNames.bind(styles);

function FlashSale() {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#005663',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const time = [
    '06:00',
    '07:00',
    '08:00',
    '09:00',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '16:00',
    '17:00',
    '18:00',
    '19:00',
    '20:00',
    '21:00',
    '22:00',
    '23:00',
    '24:00',
  ];

  const [resultFlashSale, setResultFlashSale] = useState([]);
  const [resultFlashSaleID, setResultFlashSaleID] = useState({
    text_sim: '',
    notes_sim: 'Sim như ý - Giá thanh lý',
    time_start: '',
    time_end: '',
  });
  const navigate = useNavigate();

  const fetchApiFlashSale = async () => {
    const resultAPISIM = await getFlashSale(getLocalStorage('token')['id']);
    if (resultAPISIM && resultAPISIM.length > 0) {
      setResultFlashSale(resultAPISIM);
    }
  };

  const handleGetFlashSaleID = async (id) => {
    const resultAPISIM = await getFlashSaleID(id);
    if (resultAPISIM && resultAPISIM.length > 0) {
      const dataID = {
        id: resultAPISIM[0]['id'],
        id_user: getLocalStorage('token')['id'],
        text_sim: resultAPISIM[0]['text_sim'],
        notes_sim: resultAPISIM[0]['notes_sim'],
        time_start: resultAPISIM[0]['time_start'],
        time_end: resultAPISIM[0]['time_end'],
      };
      setResultFlashSaleID(dataID);
    }
  };

  useEffect(() => {
    fetchApiFlashSale();
    if (checkTokenDashboad('3') || checkTokenDashboad('4') || checkTokenDashboad('6')) {
      console.log('OK');
    } else {
      navigate('/user/login', { replace: true });
    }
  }, []);

  const handleChangValueFlashSale = (jsonVL) => {
    setResultFlashSaleID({ ...resultFlashSaleID, ...jsonVL });
  };

  const handelUpdateFlashSale = async () => {
    const resultAPISIM = await updateFlashSaleID(resultFlashSaleID);
    if (resultAPISIM.length > 0) {
      setResultFlashSaleID({ text_sim: '', notes_sim: '', time_start: '', time_end: '' });
      setResultFlashSale(resultAPISIM);
    }
  };
  const handelAddFlashSale = async () => {
    const data = {
      ...resultFlashSaleID,
      id_user: getLocalStorage('token')['id'],
      status: '1',
    };
    const resultAPISIM = await addFlashSaleID(data);
    if (resultAPISIM.length > 0) {
      setResultFlashSaleID({ text_sim: '', notes_sim: '', time_start: '', time_end: '' });
      setResultFlashSale(resultAPISIM);
    }
  };

  const deleIDDataSim = async (data) => {
    const resultAPISIM = await deleFlashSale(data);
    setResultFlashSale(resultAPISIM);
  };
  return (
    <Container sx={{ pb: '90px' }}>
      <Grid container>
        <Grid item md={3} xs={12} sx={{ m: 1 }}>
          <Stack direction={'column'} spacing={2}>
            <TextField
              label="Nhập sim flash sale"
              size="small"
              placeholder="nhập list sim sale"
              value={resultFlashSaleID?.text_sim}
              onChange={(e) => {
                handleChangValueFlashSale({ text_sim: e.target.value });
              }}
              multiline
              rows={10}
              focused
              required
            />
            <TextField
              label="Ghi chú trên list sim"
              size="small"
              placeholder="vd: Giao sim ngay trong ngày"
              value={resultFlashSaleID?.notes_sim}
              onChange={(e) => {
                handleChangValueFlashSale({ notes_sim: e.target.value });
              }}
              focused
              required
            />
            <Grid item md={12} xs={12} sx={{ m: 1 }}>
              <b>Thời gian bắt đầu:</b>
              <br />
              {time.map((row, i) => (
                <Button
                  variant="contained"
                  color={resultFlashSaleID?.time_start === row ? `error` : `inherit`}
                  key={i}
                  sx={{ m: 0.5 }}
                  onClick={() => {
                    handleChangValueFlashSale({ time_start: row });
                  }}
                  size="small"
                >
                  {row}
                </Button>
              ))}
            </Grid>
            <Grid item md={12} xs={12} sx={{ m: 1 }}>
              <b>Thời gian kết thúc:</b>
              <br />
              {time.map((row, i) => (
                <Button
                  variant="contained"
                  color={resultFlashSaleID?.time_end === row ? `error` : `inherit`}
                  key={i}
                  sx={{ m: 0.5 }}
                  onClick={() => {
                    handleChangValueFlashSale({ time_end: row });
                  }}
                  size="small"
                >
                  {row}
                </Button>
              ))}
            </Grid>
            <Button
              variant="contained"
              color="error"
              onClick={resultFlashSaleID?.id ? handelUpdateFlashSale : handelAddFlashSale}
            >
              {resultFlashSaleID?.id ? 'Cập Nhật' : 'Lưu Lại'}
            </Button>
          </Stack>
        </Grid>
        <Grid item md={8} xs={12} sx={{ m: 1 }}>
          <TableContainer component={Paper}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">List Sim</StyledTableCell>
                  <StyledTableCell align="center">Ghi chú</StyledTableCell>
                  <StyledTableCell align="center">Thời gian sale</StyledTableCell>
                  <StyledTableCell align="center">Tác Vụ</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {resultFlashSale?.map((row, i) => (
                  <StyledTableRow key={i}>
                    <StyledTableCell align="center" className={cx('internet-tele__txt')}>
                      {row.text_sim?.split('\n').map((line, index) => (
                        <p key={index}>{line?.replace(/\s/g, '')}</p>
                      ))}
                    </StyledTableCell>
                    <StyledTableCell align="center">{row.notes_sim}</StyledTableCell>
                    <StyledTableCell align="center">
                      {row.time_start} - {row.time_end}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Button
                        onClick={() => handleGetFlashSaleID(row.id)}
                        sx={{ color: resultFlashSaleID.id === row.id ? `red` : `` }}
                      >
                        Sửa
                      </Button>
                      <Button onClick={() => deleIDDataSim({ id: row.id, id_user: row.id_user })}>Xóa</Button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Container>
  );
}

export default FlashSale;
