import Footer from './Footer/footer';
import Header from './Header/header';
function DashboardLayout({ children }) {
  return (
    <div>
      <Header />
      {children}
      <Footer />
    </div>
  );
}

export default DashboardLayout;
