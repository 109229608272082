import { useEffect, useState } from 'react';
import { Alert, Card, CardActionArea, CardContent, CardMedia, Container, Grid, Paper, Stack } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SaveIcon from '@mui/icons-material/Save';

import styles from './giaodien.module.scss';
import classNames from 'classnames/bind';
import images from '../../../assets/images';
import { checkTokenDashboad, getLocalStorage, removeLocalStorage, setLocalStorage } from '../../../utils/helper';
import { Navigate } from 'react-router-dom';
import { ButtonUI } from '../../../component/CustomerMUI';
import { listUserIDAll, updateUserID } from '../../../services/apiUsers';
import { LoadingButton } from '@mui/lab';
const cx = classNames.bind(styles);

function DashboardGiaoDien() {
  const currentDate = new Date();
  const futureDate = new Date(currentDate.getTime() + 3 * 24 * 60 * 60 * 1000).getTime();
  const arrImage = [
    { id: 1, image: images.gd1_1, title: 'GD 1 - Thu Gọn' },
    { id: 2, image: images.gd1_2, title: 'GD 1 - Chi Tiết' },
    { id: 3, image: images.gd1_3, title: 'GD 1 - Bảng Excel' },
    { id: 4, image: images.gd2_4, title: 'GD 2 - Bảng Excel' },
    { id: 5, image: images.gd2_5, title: 'GD 2 - Thu Gọn' },
    { id: 6, image: images.gd2_6, title: 'GD 2 - Chi Tiết' },
    { id: 8, image: images.gd2_8, title: 'GD 2 - Thu Gọn Mobile' },
    { id: 7, image: images.gd3_7, title: 'GD 3 - Phong Thủy' },
  ];
  const arrColor = [
    {
      name: 'Đỏ',
      color: '#cf000e',
      value: '#cf000e,0deg',
    },
    {
      name: 'Xanh',
      color: '#087b37',
      value: '#087b37,110deg',
    },
    {
      name: 'Tím',
      color: '#69087b',
      value: '#69087b,300deg',
    },
    {
      name: 'Lam',
      color: '#08547b',
      value: '#08547b,190deg',
    },
    {
      name: 'Xanh',
      color: '#081f7b',
      value: '#081f7b,240deg',
    },
    {
      name: 'Xanh',
      color: '#023fd3',
      value: '#023fd3,240deg',
    },
    {
      name: 'Xanh',
      color: '#0092d5',
      value: '#0092d5,205deg',
    },
    {
      name: 'Cam',
      color: '#f37c02',
      value: '#f37c02,0deg',
    },
    {
      name: 'Đen',
      color: '#1b1b1b',
      value: '#1b1b1b,0deg',
    },
  ];
  const [resultAdminID, setResultAdminID] = useState({});
  const [resultAdmin, setResultAdmin] = useState({});
  const getToken = getLocalStorage('token');
  const [message, setMessage] = useState('');
  const [loadingSave, setLoadingSave] = useState(false);
  const hostname = window.location.hostname;
  const fetchApiAdmin = async () => {
    if (getToken) {
      setResultAdminID({
        layoutSim: getToken['layoutSim'],
        background: getToken['background'],
      });
      setResultAdmin(getToken);
    }
  };

  useEffect(() => {
    fetchApiAdmin();
    if (
      checkTokenDashboad('1') ||
      checkTokenDashboad('2') ||
      checkTokenDashboad('3') ||
      checkTokenDashboad('4') ||
      checkTokenDashboad('6') ||
      !checkTokenDashboad('5')
    ) {
      console.log('OK');
    } else {
      Navigate('/user/login', { replace: true });
    }
  }, []);

  const handleChangValueAdmin = (jsonVL) => {
    setResultAdminID({ ...resultAdminID, ...jsonVL });
  };

  const handelUpdateAdmin = async () => {
    const body = {
      idUser: resultAdmin.id,
      data: resultAdminID,
    };
    setLoadingSave(true);
    const resultAPISIM = await updateUserID(body);
    if (resultAPISIM?.message === 'Ok') {
      setMessage('Thành Công');
      setLoadingSave(false);
      const resultAPISIM = await listUserIDAll(hostname);
      removeLocalStorage('token');
      setLocalStorage('token', { ...resultAPISIM[0], exp: futureDate });
      setResultAdmin({ ...resultAPISIM[0], exp: futureDate });
    } else {
      setLoadingSave(false);
      setMessage('Thất Bại');
    }
  };

  return (
    <Container sx={{ pb: '90px', pl: '5px', pr: '5px' }}>
      <Stack direction={'column'} sx={{ fontSize: '22px', mb: '10px' }} alignItems={'center'}>
        <b>QUẢN LÝ GIAO DIỆN SIM SỐ</b>
      </Stack>
      <Grid item xs={12} md={12}>
        {message && (
          <Alert severity={message === 'Thành Công' ? 'success' : 'error'}>
            {message === 'Thành Công' ? 'Cập Nhật Thành Công' : 'Cập Nhật Thất Bại'}
          </Alert>
        )}
      </Grid>
      <Grid container justifyContent={'center'} spacing={2}>
        {arrImage.map((img, i) => (
          <Grid item xs={12} md={6} key={i}>
            <Paper elevation={3}>
              <CardMedia component="img" width={'100%'} image={img.image} alt="green iguana" />
              <CardContent>
                <Stack direction={'column'} spacing={1}>
                  <h3 style={{ color: parseInt(resultAdmin?.layoutSim) === img.id ? 'red' : '' }}>
                    {img.title} {parseInt(resultAdmin?.layoutSim) === img.id ? '(Đang sử dụng)' : ''}
                  </h3>
                  <b>Chọn Màu Giao Diện:</b>
                  <div style={{ overflowX: 'scroll' }}>
                    <div style={{ width: 'max-content' }}>
                      <Stack direction={'row'}>
                        {arrColor.map((color, i) => (
                          <ButtonUI
                            bgrColorBtn={color.color}
                            colorText="#fff"
                            key={i}
                            small
                            onClick={() => {
                              handleChangValueAdmin({ background: color.value, layoutSim: img.id });
                            }}
                            sx={{ m: 0.3 }}
                          >
                            {color.name}{' '}
                            <CheckCircleIcon
                              fontSize="small"
                              sx={{
                                color:
                                  resultAdminID?.background === color.value &&
                                  parseInt(resultAdminID?.layoutSim) === img.id
                                    ? 'gold'
                                    : '#d8d8d854',
                              }}
                            />
                          </ButtonUI>
                        ))}
                      </Stack>
                    </div>
                  </div>
                  {!loadingSave ? (
                    <ButtonUI color="error" onClick={handelUpdateAdmin}>
                      Lưu Thay Đổi
                    </ButtonUI>
                  ) : (
                    <LoadingButton loading loadingPosition="start" startIcon={<SaveIcon />} variant="outlined">
                      Lưu Thay Đổi
                    </LoadingButton>
                  )}
                </Stack>
              </CardContent>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default DashboardGiaoDien;
