import { useEffect, useState } from 'react';
import { Alert, Button, Container, Grid, Stack, TextField } from '@mui/material';
import { listUserID, updateUserID } from '../../../services/apiUsers';
import { checkTokenDashboad } from '../../../utils/helper';
import { useNavigate } from 'react-router-dom';

function DashboardNotifiSim() {
  const hostname = window.location.hostname;
  const [resultNotifiSim, setResultNotifiSim] = useState({});
  const [message, setMessage] = useState('');
  const [resultNotifiSimID, setResultNotifiSimID] = useState({ NOTIFI: '', LINKNOTIFI: '' });
  const navigate = useNavigate();

  const fetchApiNotifiSim = async () => {
    const resultAPISIM = await listUserID(hostname);
    if (resultAPISIM && resultAPISIM.length > 0) {
      setResultNotifiSim(resultAPISIM[0]);
      setResultNotifiSimID({ NOTIFI: resultAPISIM[0]['NOTIFI'], LINKNOTIFI: resultAPISIM[0]['LINKNOTIFI'] });
    }
  };

  useEffect(() => {
    fetchApiNotifiSim();
    if (
      checkTokenDashboad('1') ||
      checkTokenDashboad('2') ||
      checkTokenDashboad('3') ||
      checkTokenDashboad('4') ||
      checkTokenDashboad('6')
    ) {
      console.log('OK');
    } else {
      navigate('/user/login', { replace: true });
    }
  }, []);

  const handleChangValueNotifiSim = (jsonVL) => {
    setResultNotifiSimID({ ...resultNotifiSimID, ...jsonVL });
  };

  const handelUpdateNotifiSim = async () => {
    const body = {
      idUser: resultNotifiSim.id,
      data: resultNotifiSimID,
    };
    const resultAPISIM = await updateUserID(body);
    if (resultAPISIM?.message === 'Ok') {
      setMessage('Thành Công');
    } else {
      setMessage('Thất Bại');
    }
  };
  return (
    <Container>
      <Grid container>
        <Grid item xs={12} md={12}>
          {message && (
            <Alert severity={message === 'Thành Công' ? 'success' : 'error'}>
              {message === 'Thành Công' ? 'Cập Nhật Thành Công' : 'Cập Nhật Thất Bại'}
            </Alert>
          )}
        </Grid>
        <Grid item md={6} xs={12} sx={{ m: 1 }}>
          <Stack direction={'column'} spacing={2}>
            <TextField
              label="Tiêu đề thông báo"
              size="small"
              placeholder="VD: Giảm giá cực sốc trong tháng"
              value={resultNotifiSimID?.NOTIFI || ''}
              //   defaultValue={resultNotifiSim?.NOTIFI}
              onChange={(e) => {
                handleChangValueNotifiSim({ NOTIFI: e.target.value });
              }}
              focused
              required
            />
            <TextField
              label="Đường link thông báo"
              size="small"
              placeholder={`VD: https://${hostname}/thong-bao`}
              value={resultNotifiSimID?.LINKNOTIFI || ''}
              //   defaultValue={resultNotifiSim?.LINKNOTIFI}
              onChange={(e) => {
                handleChangValueNotifiSim({ LINKNOTIFI: e.target.value });
              }}
              focused
              required
            />
            <Button variant="contained" color="error" onClick={handelUpdateNotifiSim}>
              Cập Nhật
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
}

export default DashboardNotifiSim;
