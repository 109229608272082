import { useEffect } from 'react';
import Iframe from 'react-iframe';
import { checkTokenDashboad, getLocalStorage } from '../../../utils/helper';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Stack } from '@mui/material';
function Photo({ sim = '', price = '', isQl = true }) {
  const navigate = useNavigate();
  const token = localStorage.getItem('token_sv');
  useEffect(() => {
    if (
      checkTokenDashboad('1') ||
      checkTokenDashboad('2') ||
      checkTokenDashboad('3') ||
      checkTokenDashboad('4') ||
      checkTokenDashboad('6') ||
      checkTokenDashboad('5')
    ) {
      console.log('OK');
    } else {
      navigate('/user/login', { replace: true });
    }
  }, []);
  return (
    <>
      {isQl ? (
        <Stack direction={'column'} spacing={4}>
          <Button
            variant={'contained'}
            size="small"
            color="info"
            onClick={() => {
              navigate('/dashboard', { replace: true });
            }}
          >
            Quay lại trang chủ
          </Button>
          <Iframe
            src={`https://photo.khoso.com.vn/#/?fb=${token}`}
            position="absolute"
            width="100%"
            height="100%"
          ></Iframe>
        </Stack>
      ) : (
        <Iframe
          src={`https://photo.khoso.com.vn/#/?fb=${token}&sim=${sim}&price=${price}`}
          position="absolute"
          width="100%"
          height="100%"
        ></Iframe>
      )}
    </>
  );
}

export default Photo;
