import React, { useContext, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import images from '../../../assets/images';
import styles from './header.module.scss';
import { Hidden } from '@mui/material';
const cx = classNames.bind(styles);

function Header() {
  const navigate = useNavigate();
  const hostname = window.location.hostname;

  const handleNav = () => {
    navigate('/dashboard', { replace: true });
    window.location.reload();
  };

  return (
    <>
      <header className={cx('wrapper')}>
        <div className={cx('inner')}>
          <Hidden only={['sm', 'xs']}>
            <Grid container justifyContent={'center'}>
              <Grid item xs={6} className={cx('logo-center')} onClick={() => handleNav()}>
                <img src={images.logokhoso} alt="Khosim" className={cx('logo')} />
                <label className={cx('color-logo-title')}>
                  <b>HỆ THỐNG SIM</b>
                </label>
              </Grid>
            </Grid>
          </Hidden>
          <Hidden only={['md', 'lg', 'xl']}>
            <Grid container>
              <Grid item xs={12} className={cx('logo-center')} onClick={() => handleNav()}>
                <img src={images.logokhoso} alt="Khosim" className={cx('logo')} />
                <label className={cx('color-logo-title')}>
                  <b>HỆ THỐNG SIM</b>
                </label>
              </Grid>
            </Grid>
          </Hidden>
        </div>
      </header>
    </>
  );
}
export default Header;
