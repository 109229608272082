import { useEffect, useRef, useState } from 'react';
import {
  Button,
  Container,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

import classNames from 'classnames/bind';
import styles from './news.module.scss';
import { checkTokenDashboad, getLocalStorage } from '../../../utils/helper';
import { useNavigate } from 'react-router-dom';
import { listNewsID, addNewsID, getNewsID, updateNewsID, deleteNews } from '../../../services/apiNews';
import { API_URL_TT } from '../../../config/config';
import axios from 'axios';
const cx = classNames.bind(styles);

function News() {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#005663',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const [resultNews, setResultNews] = useState([]);
  const [resultNewsID, setResultNewsID] = useState({ title: '', title_short: '' });
  const navigate = useNavigate();
  const editorRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [content, setContent] = useState('');
  const [messageImageSize, setMessageImageSize] = useState('');
  const [compressedImage, setCompressedImage] = useState(null);
  const [isShowFormData, setIsShowFormData] = useState(false);

  const fetchApiNews = async () => {
    const resultAPISIM = await listNewsID(getLocalStorage('token')['id']);
    if (resultAPISIM && resultAPISIM.length > 0) {
      setResultNews(resultAPISIM);
    }
  };

  const handleGetNewsID = async (id) => {
    setCompressedImage('');
    setIsShowFormData(true);
    const resultAPISIM = await getNewsID(id, getLocalStorage('token')['id']);
    if (resultAPISIM && resultAPISIM.length > 0) {
      const dataID = {
        id: resultAPISIM[0]['id'],
        id_user: getLocalStorage('token')['id'],
        title: resultAPISIM[0]['title'],
        title_short: resultAPISIM[0]['title_short'],
        content: resultAPISIM[0]['content'],
      };
      // console.log(dataID);
      setResultNewsID(dataID);
      setCompressedImage(`${API_URL_TT}/upload.php?image=${id}.png`);
    }
  };

  useEffect(() => {
    fetchApiNews();
    if (
      checkTokenDashboad('1') ||
      checkTokenDashboad('2') ||
      checkTokenDashboad('3') ||
      checkTokenDashboad('4') ||
      checkTokenDashboad('6') ||
      !checkTokenDashboad('5')
    ) {
      console.log('OK');
    } else {
      navigate('/user/login', { replace: true });
    }
  }, []);

  const handleChangValueNews = (jsonVL) => {
    setResultNewsID({ ...resultNewsID, ...jsonVL });
  };

  const handelUpdateNews = async () => {
    const resultAPISIM = await updateNewsID({ ...resultNewsID, content: content });
    handleSubmit(resultNewsID?.id);
    if (resultAPISIM.length > 0) {
      setResultNewsID({ title: '', title_short: '' });
      setCompressedImage('');
      setIsShowFormData(false);
      setResultNews(resultAPISIM);
    }
  };
  const handelAddNews = async () => {
    const data = {
      ...resultNewsID,
      id_user: getLocalStorage('token')['id'],
      status: '1',
      content: content,
    };
    const resultAPISIM = await addNewsID(data);
    if (resultAPISIM.length > 0) {
      handleSubmit(resultAPISIM[0]?.id);
      setResultNewsID({ title: '', title_short: '' });
      setCompressedImage('');
      setIsShowFormData(false);
      setResultNews(resultAPISIM);
    }
  };

  const deleIDDataSim = async (data) => {
    const resultAPISIM = await deleteNews(data);
    setResultNews(resultAPISIM);
  };

  const handleImageUpload = (event) => {
    const image = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setCompressedImage(reader.result);
    };
    reader.readAsDataURL(event.target.files[0]);
    if (image.size <= 1048576) {
      setMessageImageSize('');
      setSelectedImage(image);
    } else {
      setMessageImageSize('Kích thước ảnh không được vượt quá 1MB.');
    }
  };

  const handleSubmit = async (idname) => {
    if (selectedImage) {
      const formData = new FormData();
      const nameID = resultNewsID?.id ? resultNewsID?.id : idname;
      formData.append('image', selectedImage, `${nameID}.png`);
      // console.log(formData);

      try {
        const response = await axios.post(`${API_URL_TT}/upload.php`, formData);
        console.log(response.data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <Container sx={{ pb: '90px' }}>
      <Grid container>
        {!isShowFormData && (
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              setIsShowFormData(true);
            }}
          >
            THÊM BÀI VIẾT
          </Button>
        )}
        {isShowFormData && (
          <Grid item md={12} xs={12} sx={{ mt: 1 }}>
            <Stack direction={'column'} spacing={2}>
              <span>
                <b>Up ảnh đại diện tin</b>
              </span>
              <input type="file" onChange={handleImageUpload} />
              {compressedImage && <img src={compressedImage} alt="ảnh đại diện" width={'100px'} />}
              <span>
                <b className={cx('color-red')}>{messageImageSize}</b>
              </span>
              <TextField
                label="Tiêu Đề"
                size="small"
                placeholder="Nhập tiêu đề"
                value={resultNewsID?.title}
                onChange={(e) => {
                  handleChangValueNews({ title: e.target.value });
                }}
                focused
                required
              />
              <TextField
                label="Tóm tắt nội dung"
                size="small"
                placeholder="Nhập nội dung tóm tắt"
                value={resultNewsID?.title_short}
                onChange={(e) => {
                  handleChangValueNews({ title_short: e.target.value });
                }}
                focused
                required
              />
              <span>
                <b>Nhập nội dung bài viết</b>
              </span>

              <SunEditor
                defaultValue={resultNewsID?.content}
                setContents={resultNewsID?.content}
                onChange={(content) => {
                  setContent(content);
                }}
                setOptions={{
                  buttonList: [
                    ['undo', 'redo'],
                    ['font', 'fontSize', 'formatBlock'],
                    ['paragraphStyle', 'blockquote'],
                    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                    ['fontColor', 'hiliteColor', 'textStyle'],
                    ['removeFormat'],
                    ['outdent', 'indent'],
                    ['align', 'horizontalRule', 'list', 'table'],
                    ['link', 'image', 'video'],
                    ['fullScreen', 'showBlocks', 'codeView'],
                    ['preview', 'print'],
                    ['save'],
                  ],
                }}
              />
              <Button variant="contained" color="error" onClick={resultNewsID?.id ? handelUpdateNews : handelAddNews}>
                {resultNewsID?.id ? 'Cập Nhật' : 'Lưu Lại'}
              </Button>
            </Stack>
          </Grid>
        )}
        <Grid item md={12} xs={12} sx={{ mt: 1 }}>
          <TableContainer component={Paper}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  {/* <StyledTableCell align="center">Ảnh</StyledTableCell> */}
                  <StyledTableCell align="center">Tiêu Đề</StyledTableCell>
                  <StyledTableCell align="center">Tóm Tắt</StyledTableCell>
                  <StyledTableCell align="center">Tác Vụ</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {resultNews.length > 0 &&
                  resultNews?.map((row, i) => (
                    <StyledTableRow key={i}>
                      {/* <StyledTableCell align="center">
                        <img src={`${API_URL_TT}/upload.php?image=${row.id}.png`} alt="ảnh" width={'50px'} />
                      </StyledTableCell> */}
                      <StyledTableCell align="center">
                        <span className={cx('internet-tele__txt_title')}>{row.title} </span>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <span className={cx('internet-tele__txt')}>{row.title_short} </span>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Button
                          onClick={() => handleGetNewsID(row.id)}
                          sx={{ color: resultNewsID.id === row.id ? `red` : `` }}
                        >
                          Sửa
                        </Button>
                        <Button onClick={() => deleIDDataSim({ id: parseInt(row.id), id_user: parseInt(row.id_user) })}>
                          Xóa
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Container>
  );
}

export default News;
