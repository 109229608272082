import { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

import classNames from 'classnames/bind';
import styles from './formatsim.module.scss';
import {
  addFormatSimID,
  deleFormatSimID,
  getFormatSim,
  getFormatSimID,
  updateFormatSimID,
} from '../../../services/apiDigiShop';
import { checkTokenDashboad } from '../../../utils/helper';
import { useNavigate } from 'react-router-dom';
const cx = classNames.bind(styles);

function FormatSim() {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#005663',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const [resultFormatSim, setResultFormatSim] = useState([]);
  const [resultFormatSimID, setResultFormatSimID] = useState({ dangsim: '', dangsim_show: '' });
  const navigate = useNavigate();

  const fetchApiFormatSim = async () => {
    const resultAPISIM = await getFormatSim();
    if (resultAPISIM && resultAPISIM.length > 0) {
      setResultFormatSim(resultAPISIM);
    }
  };

  const handleGetFormatSimID = async (id) => {
    const resultAPISIM = await getFormatSimID(id);
    if (resultAPISIM && resultAPISIM.length > 0) {
      const dataID = {
        id: resultAPISIM[0]['id'],
        dangsim: resultAPISIM[0]['dangsim'],
        dangsim_show: resultAPISIM[0]['dangsim_show'],
      };
      setResultFormatSimID(dataID);
    }
  };

  useEffect(() => {
    fetchApiFormatSim();
    if (!checkTokenDashboad('3')) {
      navigate('/user/login', { replace: true });
    }
  }, []);

  const handleChangValueFormatSim = (jsonVL) => {
    setResultFormatSimID({ ...resultFormatSimID, ...jsonVL });
  };

  const handelUpdateFormatSim = async () => {
    const resultAPISIM = await updateFormatSimID(resultFormatSimID);
    if (resultAPISIM.length > 0) {
      setResultFormatSimID({ dangsim: '', dangsim_show: '' });
      setResultFormatSim(resultAPISIM);
    }
  };
  const handelAddFormatSim = async () => {
    const data = {
      ...resultFormatSimID,
      id_user: '1',
      status: '1',
    };
    const resultAPISIM = await addFormatSimID(data);
    if (resultAPISIM.length > 0) {
      setResultFormatSimID({ dangsim: '', dangsim_show: '' });
      setResultFormatSim(resultAPISIM);
    }
  };

  const deleIDDataSim = async (data) => {
    const resultAPISIM = await deleFormatSimID(data);
    setResultFormatSim(resultAPISIM);
  };
  return (
    <Container sx={{ pb: '65px' }}>
      <Grid container>
        <Grid item md={3} xs={12} sx={{ m: 1 }}>
          <Stack direction={'column'} spacing={2}>
            <TextField
              label="Dạng Sim"
              size="small"
              placeholder="8484-4848*"
              value={resultFormatSimID?.dangsim}
              onChange={(e) => {
                handleChangValueFormatSim({ dangsim: e.target.value });
              }}
              focused
              required
            />
            <TextField
              label="Định Dạng Tách Sim"
              size="small"
              placeholder="vd: 0984.49.49.49 -> nhập: xxxx.xx.xx.xx"
              value={resultFormatSimID?.dangsim_show}
              onChange={(e) => {
                handleChangValueFormatSim({ dangsim_show: e.target.value });
              }}
              focused
              required
            />
            <Button
              variant="contained"
              color="error"
              onClick={resultFormatSimID?.id ? handelUpdateFormatSim : handelAddFormatSim}
            >
              {resultFormatSimID?.id ? 'Cập Nhật' : 'Lưu Lại'}
            </Button>
          </Stack>
        </Grid>
        <Grid item md={6} xs={12} sx={{ m: 1 }}>
          <TableContainer component={Paper}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Dạng Sim</StyledTableCell>
                  <StyledTableCell align="center">Dạng Tách Sim</StyledTableCell>
                  <StyledTableCell align="center">Tác Vụ</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {resultFormatSim?.map((row, i) => (
                  <StyledTableRow key={i}>
                    <StyledTableCell align="center">{row.dangsim}</StyledTableCell>
                    <StyledTableCell align="center">{row.dangsim_show}</StyledTableCell>
                    <StyledTableCell align="center">
                      <Button
                        onClick={() => handleGetFormatSimID(row.id)}
                        sx={{ color: resultFormatSimID.id === row.id ? `red` : `` }}
                      >
                        Sửa
                      </Button>
                      <Button onClick={() => deleIDDataSim({ id: row.id, id_user: row.id_user })}>Xóa</Button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Container>
  );
}

export default FormatSim;
