import { useContext, useEffect, useRef, useState } from 'react';

import { getNewsID, listNewsID } from '../../../services/apiNews';
import { convertToSlug, getLastValue, getLocalStorage, setLocalStorage } from '../../../utils/helper';
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Collapse,
  Container,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ShareIcon from '@mui/icons-material/Share';

import classNames from 'classnames/bind';
import styles from './news.module.scss';
import { red, green, blue, yellow, purple, orange } from '@mui/material/colors';
import { listUserID } from '../../../services/apiUsers';
import { ThemeContext } from '../../../component/ThemeContext/ThemeContext';
import { API_URL_TT } from '../../../config/config';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
const cx = classNames.bind(styles);

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));
function News() {
  const [resultNews, setResultNews] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [authDomain, setAuthDomain] = useState({});
  const [detailNews, setDetailNews] = useState({});
  const hostname = window.location.hostname;
  const contextApiUser = useContext(ThemeContext);
  const { idNews } = useParams();
  const navigate = useNavigate();

  const fetchApiUserID = async () => {
    const resultAPISIM = await listUserID(hostname);
    if (resultAPISIM.length > 0) {
      setLocalStorage('khoso', resultAPISIM[0]);
      setAuthDomain(resultAPISIM[0]);
      contextApiUser.toggleDataUser(resultAPISIM[0]);
      if (idNews) {
        handleGetNewsID(idNews);
      } else {
        fetchApiNews(resultAPISIM[0]['id']);
      }
    }
  };
  const fetchApiNews = async (id) => {
    const resultAPISIM = await listNewsID(id);
    if (resultAPISIM && resultAPISIM.length > 0) {
      document.title = 'bài viết sim số';
      setResultNews(resultAPISIM);
    }
  };

  const handleGetNewsID = async (id) => {
    const idnews = idNews ? idNews : id;
    if (idnews) {
      const resultAPISIM = await getNewsID(getLastValue(idnews), getLocalStorage('khoso')['id']);
      if (resultAPISIM && resultAPISIM.length > 0) {
        setDetailNews(resultAPISIM[0]);
        document.title = resultAPISIM[0].title;
      }
    }
  };
  useEffect(() => {
    fetchApiUserID();
  }, [idNews]);

  function getRandomColor() {
    const colors = [red, green, blue, yellow, purple, orange];
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex][500];
  }

  return (
    <div>
      {idNews && (
        <Helmet>
          <meta property="og:image" content={`${API_URL_TT}/upload.php?image=${detailNews.id}.png`} />
          <meta property="og:title" content={detailNews.title} />
          <meta property="og:description" content={detailNews.title_short} />
          <meta property="og:type" content="website" />
          <title>{detailNews.title}</title>
        </Helmet>
      )}

      <Container sx={{ pb: '90px', bgcolor: '#f9f9f9', borderRadius: '20px' }}>
        {!idNews && resultNews.length > 0 && (
          <Grid container spacing={1}>
            <Grid item md={12} xs={12} sx={{ mt: 1 }}>
              <label className={cx('title-tt')}>Tất cả bài viết hay tại {hostname}</label>
            </Grid>
            {resultNews?.map((tt, i) => (
              <Grid item md={4} xs={12} sx={{ mt: 1 }} key={i}>
                <Card sx={{ borderRadius: 5 }}>
                  <CardHeader
                    avatar={
                      <Avatar sx={{ bgcolor: getRandomColor() }} aria-label="recipe" className={cx('text-hoa')}>
                        {tt.title.slice(0, 1)}
                      </Avatar>
                    }
                    title={tt.title}
                    subheader={tt.update_date}
                    sx={{ height: '40px' }}
                    titleTypographyProps={{ fontWeight: '600' }}
                  />
                  <CardMedia
                    component="img"
                    height="194"
                    image={`${API_URL_TT}/upload.php?image=${tt.id}.png`}
                    alt={tt.title}
                    width={'100%'}
                  />
                  <CardContent sx={{ height: '42px' }}>
                    <Typography variant="body2" color="text.secondary" className={cx('news_internet-tele__txt')}>
                      {tt.title_short}
                    </Typography>
                  </CardContent>
                  <CardActions disableSpacing sx={{ mt: '-25px' }}>
                    <IconButton aria-label="share">
                      <ShareIcon />
                    </IconButton>
                    <ExpandMore
                      onClick={() => {
                        navigate(convertToSlug(tt.title) + `-${tt.id}`, { replace: true });
                        handleGetNewsID(tt.id);
                      }}
                    >
                      chi tiết
                    </ExpandMore>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
        {idNews && (
          <Grid container spacing={1}>
            <Grid item md={12} xs={12} sx={{ mt: 1 }}>
              <Button variant="text" startIcon={<KeyboardArrowLeftIcon />} component={Link} to="/bai-viet">
                Tất cả bài viết
              </Button>
            </Grid>
            <Grid item md={12} xs={12} sx={{ mt: 1, display: 'flex', justifyContent: 'center' }}>
              {detailNews?.id && (
                <img src={`${API_URL_TT}/upload.php?image=${detailNews.id}.png`} alt="Ảnh" className={cx('img')} />
              )}
            </Grid>
            <Stack direction={'column'} spacing={1} sx={{ width: '100%' }}>
              <h1>{detailNews.title}</h1>
              <span>{detailNews.update_date}</span>
              <h2>{detailNews.title_short}</h2>
              <div dangerouslySetInnerHTML={{ __html: detailNews.content }}></div>
            </Stack>
          </Grid>
        )}
      </Container>
    </div>
  );
}

export default News;
