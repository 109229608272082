import { useEffect, useState } from 'react';
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Skeleton,
  Stack,
  TextField,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SimCardIcon from '@mui/icons-material/SimCard';

import * as apiCheckSimDaily from '../../../services/apiCheckSimDaily';
import classNames from 'classnames/bind';
import styles from './simdaily.module.scss';
import { red } from '@mui/material/colors';
import { FrameInfoSim } from '../../../component/CustomerMUI';
import HelpIcon from '@mui/icons-material/Help';
import { checkTokenDashboad, getLocalStorage, numberFormat, totalPriceDiscount } from '../../../utils/helper';
import FrameInfoSimDF from '../../../component/CustomerMUI/FrameInfoSim/FrameInfoSimDF';
import ThemeLoad from '../../../component/CustomerMUI/ThemeLoad/ThemeLoad';
import { useNavigate } from 'react-router-dom';
import { listSimAll } from '../../../services/listSim';
const cx = classNames.bind(styles);

function SimDaily() {
  const [textSearchChange, setTextSearchChange] = useState('');
  const [textSearch, setTextSearch] = useState('');
  const [resultInfoSim, setResultInfoSim] = useState({});
  const [loadingTheme, setLoadingTheme] = useState(false);
  const navigate = useNavigate();
  const hostname = window.location.hostname;

  const fetchCheckSimDaily = async (sim) => {
    const simsochuan = sim.replace(/\./g, '').trim();
    const resultKhoso = await listSimAll(hostname, 1, simsochuan);
    if (resultKhoso.totalRows > 0) {
      setLoadingTheme(false);
      const arrInfoSim = {
        simso: resultKhoso.data[0]['sim_show'],
        giakhach: resultKhoso.data[0]['price'],
        infotho: [
          {
            price: resultKhoso.data[0]['price'],
            priceGoc: totalPriceDiscount(resultKhoso.data[0])[0].replace(/\./g, '').trim(),
            dateUpdateSim: '1 ngày trước',
          },
        ],
      };
      setResultInfoSim(arrInfoSim);
    } else {
      const resultAPISIM = await apiCheckSimDaily.checkSimDaily(simsochuan);
      if (resultAPISIM.length > 0) {
        setLoadingTheme(false);
        setResultInfoSim(resultAPISIM[0]);
      } else {
        setLoadingTheme(false);
      }
    }
  };
  useEffect(() => {
    if (checkTokenDashboad('3') || checkTokenDashboad('5')) {
      console.log('OK');
    } else {
      navigate('/user/login', { replace: true });
    }
  }, []);

  const handleChangeSearchSim = (e) => {
    setTextSearchChange(e.target.value);
  };
  const handleEnterKey = (e) => {
    if (e.key === 'Enter') {
      setLoadingTheme(true);
      setTextSearch(e.target.value);
      fetchCheckSimDaily(e.target.value);
    }
  };
  const onClickSearchSubmit = () => {
    setLoadingTheme(true);
    setTextSearch(textSearchChange);
    fetchCheckSimDaily(textSearchChange);
  };

  const handleDetailSim = (gia, giadaly, updatesim) => {
    const hoahong = parseInt(gia) - parseInt(giadaly);
    const arrInfoSim = [
      gia && `Giá bán lẻ: ${numberFormat(gia)}`,
      giadaly && `Giá đại lý: ${numberFormat(giadaly)}`,
      giadaly && `Lãi: ${numberFormat(hoahong.toString())}`,
      updatesim && `Cập nhật: ${numberFormat(updatesim)}`,
      getLocalStorage('token') && `Hotline/Zalo: ${getLocalStorage('token')['HOTTLINE_SIM']}`,
    ];
    return arrInfoSim;
  };
  return (
    <Container sx={{ pb: '75px' }}>
      <Grid container justifyContent="center">
        <Grid item md={6} xs={12}>
          <header className={cx('wrapper-header')}>
            <div className={cx('inner-header')}>
              <Grid container>
                <Grid item md={12} xs={12}>
                  <Paper elevation={3} sx={{ p: '0px 2px', display: 'flex', alignItems: 'center', borderRadius: 2 }}>
                    <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      placeholder={`Nhập số điện thoại`}
                      inputProps={{ 'aria-label': 'Tìm kiếm sim số' }}
                      value={textSearchChange}
                      onChange={handleChangeSearchSim}
                      onKeyPress={handleEnterKey}
                      fullWidth
                    />
                    {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" /> */}
                    <IconButton
                      sx={{ m: '2px' }}
                      className={cx('bgr-default')}
                      aria-label="directions"
                      onClick={onClickSearchSubmit}
                    >
                      <SearchIcon sx={{ color: 'aliceblue' }} />
                    </IconButton>
                  </Paper>
                </Grid>
              </Grid>

              <Grid item xs={12} sx={{ mt: 1 }}>
                <label className={cx('color-trang')}>
                  <b>Nhập số điện thoại bạn muốn tra cứu thông tin</b>
                </label>
              </Grid>
            </div>
          </header>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" sx={{ mt: 1 }}>
        <Grid item md={6} xs={12}>
          {loadingTheme && (
            <Paper elevation={2}>
              <Card variant="outlined">
                <CardContent sx={{ p: 0, '&:last-child': { pb: 0 } }}>
                  <div style={{ padding: '10px' }}>
                    <Skeleton variant="rectangular" height={125} />
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                  </div>
                </CardContent>
              </Card>
            </Paper>
          )}
        </Grid>
      </Grid>

      {!loadingTheme &&
        resultInfoSim?.infotho?.map((row, i) => (
          <Grid key={i} container justifyContent="center">
            <Grid item md={6} xs={12} sx={{ mt: 1 }}>
              <Card variant="outlined" sx={{ borderRadius: 3 }}>
                <CardContent sx={{ p: 0, '&:last-child': { pb: 0 } }}>
                  <FrameInfoSimDF
                    fontSize="medium"
                    fontSizeTitle="20"
                    fontSizeDes="20"
                    bgrFrame={'red'}
                    title={`KIỂM TRA THÔNG TIN SIM`}
                    des={resultInfoSim?.simso}
                    arrDetailSim={handleDetailSim(row.price, row.priceGoc, row.dateUpdateSim)}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        ))}

      {!loadingTheme && resultInfoSim?.simso && (
        <Grid container justifyContent="center">
          <Grid item md={6} xs={12} sx={{ mt: 1 }}>
            <Card variant="outlined" sx={{ borderRadius: 3 }}>
              <CardContent className={cx('bg-xanhlam')} sx={{ p: 0.5, '&:last-child': { pb: 1, pt: 1 } }}>
                <Stack direction="row" spacing={0} justifyContent="center" alignItems="center">
                  <HelpIcon color="gold" />
                  <h2>CÁCH CHECK SIM CHỦ ĐỘNG</h2>
                </Stack>
                <Stack direction="row" spacing={0} justifyContent="center" alignItems="flex-end">
                  <p style={{ padding: '10px' }}>
                    Khi tư vấn khách cách check nhanh nhất là gọi thẳng trực tiếp vào số điện thoại đó.
                    <br />- Nếu thuê bao hoặc tút tút ngay thì 90% là còn
                    <br />- Cảm thấy khách ưng số đó thì lúc đó hãy nhắn để mình kiểm tra kỹ và giữ số trên KHO
                    <br /> - TRÁNH lọc 10-20 sim cho khách rồi kêu mình check luôn. Vì sim rất nhiều kho. check như vậy
                    mất thời gian và lại không chốt
                  </p>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </Container>
  );
}

export default SimDaily;
